<template>
	<div>
		<b-card
			no-body
		>
			<div class="m-2">
				<!-- Table Top -->
				<b-row>
					<b-col
						cols="12"
						md="4"
					>
						<b-form-group
							label="Name"
							label-for="name"
							class="mb-0"
						>
							<b-form-input
								id="name"
								v-model="search.name"
								class="form-control"
								placeholder="Please Enter Name"
							/>
						</b-form-group>
					</b-col>
					<b-col
						cols="12"
						md="4"
					>
						<b-form-group
							label="Status"
							label-for="status"
							class="mb-0"
						>
							<v-select
								v-model="search.status"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:options="statusOptions"
								:reduce="val => val.value"
								input-id="status"
							/>
						</b-form-group>
					</b-col>
					<b-col
						cols="12"
						md="4"
						class="d-flex align-items-end justify-content-end"
					>
						<b-button
							variant="outline-primary"
							@click="resetFilter()"
						>
							Reset
						</b-button>
						<b-button
							variant="primary"
							class="ml-2"
							@click="searchFilter()"
						>
							Search
						</b-button>
					</b-col>
				</b-row>
			</div>
		</b-card>
		<b-card
			no-body
		>
			<div class="m-2">

				<!-- Table Top -->
				<b-row>
					<!-- Search -->
					<b-col
						cols="12"
						v-if="$can('create', 'bonusgroup')"
					>
						<div class="d-flex align-items-center justify-content-end">
							<b-button
								variant="primary"
								@click="showAddBonusGroupModal()"
							>
								<span class="text-nowrap">
									<feather-icon icon="PlusIcon"/> New
								</span>
							</b-button>
						</div>
					</b-col>
				</b-row>

			</div>
			<b-table
				ref="refBonusGroupListTable"
				class="position-relative table-white-space mb-0 max-height-table"
				sticky-header
				head-variant="light"
				:items="bonusGroupLists"
				responsive
				:fields="tableColumns"
				primary-key="index"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No matching records found"
				:sort-desc.sync="isSortDirDesc"
			>
				<template #cell(index)="data">
					{{ perPage * (currentPage - 1) + data.index + 1 }}
				</template>
        <template #cell(name)="data">
          <b-link
            class="cursor-pointer text-primary"
            @click="showEditBonusGroupModal(data.item)"
          >
            {{data.item.name}}
          </b-link>
        </template>
				<!-- Column: Status -->
				<template #cell(status)="data">
					<div :class="`text-capitalize align-items-center d-flex`">
						<span :class="`dot bg-${resolveStatus(data.item.status).variant}`" />{{ resolveStatus(data.item.status).label }}
					</div>
				</template>

				<template #cell(created_at)="data">
					{{ formatDateTime(data.item.created_at).replace('.000000Z', '') }}
				</template>

				<template #cell(updated_at)="data">
					{{ formatDateTime(data.item.updated_at).replace('.000000Z', '') }}
				</template>

				<template #cell(action)="data">
					<b-link
					v-if="$can('edit', 'bonusgroup')"
						class="font-weight-bold text-nowrap"
						@click="showEditBonusGroupModal(data.item)"
					>
						Edit
					</b-link>
				</template>

			</b-table>
			<div class="p-2">
				<b-row>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
					</b-col>
					<!-- Pagination -->
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>

						<b-pagination
							v-model="currentPage"
							:total-rows="totalBonusGroup"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>

					</b-col>

				</b-row>
			</div>
		</b-card>

		<!-- Modal: Add Bonus Group -->
		<add-bonus-group-modal
			@refetch-data="refetchData"
		/>

		<!-- Modal: Edit Bonus Group -->
		<edit-bonus-group-modal
			:bonus-group-detail.sync="bonusGroupDetail"
			@refetch-data="refetchData"
		/>
	</div>
</template>

<script>
import {
	BButton, BModal, VBModal,
	BCard, BRow, BCol, BFormInput, BTable, BTr, BTh, BMedia, BAvatar, BLink,
	BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ref, watch, computed, onUnmounted } from '@vue/composition-api'
import { formatDateTime } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import bonusStoreModule from '@/views/bonus/bonusStoreModule'
import AddBonusGroupModal from '@/views/bonus/AddBonusGroupModal.vue'
import EditBonusGroupModal from '@/views/bonus/EditBonusGroupModal.vue'

export default {
	components: {
		AddBonusGroupModal,
		EditBonusGroupModal,
		BButton,
		BFormGroup,
		BModal,
		BCard,
		BRow,
		BCol,
		BFormInput,
		BTable,
		BTr,
		BTh,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		vSelect,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	data() {
		return {
			bonusGroupDetail: null,
		}
	},
	methods: {
		showAddBonusGroupModal() {
			this.$bvModal.show('modal-add-bonus-group')
		},
		showEditBonusGroupModal(data) {
			this.bonusGroupDetail = data
			this.$bvModal.show('modal-edit-bonus-group')
		},
	},
	setup() {
		if (!store.hasModule('bonus')) store.registerModule('bonus', bonusStoreModule)

		const toast = useToast()

		const search = ref({
			name: '',
			status: '',
		})

		const statusFilter = ref(null)
		const nameFilter = ref(null)

		const perPage = ref(25)
		const totalBonusGroup = ref(0)
		const currentPage = ref(1)
		const perPageOptions = [10, 25, 50, 100]
		const sortBy = ref('id')
		const isSortDirDesc = ref(true)

		const refBonusGroupListTable = ref(null)

		const tableColumns = [
			{ key: 'index', label: '#' },
			'name',
			'status',
			'created_by',
			'updated_by',
			'created_at',
			'updated_at',
			'action',
		]

		const refetchData = () => {
			refBonusGroupListTable.value.refresh()
		}

		watch([currentPage, nameFilter, statusFilter], () => {
			refetchData()
		})

		const bonusGroupLists = (ctx, callback) => {
			store.dispatch('bonus/fetchBonusGroups', {
				name: nameFilter.value,
				status: statusFilter.value,
				page: currentPage.value,
			})
				.then(response => {
					const { bonusGroups, total, pageSize } = response
					callback(bonusGroups)
					totalBonusGroup.value = total
					perPage.value = pageSize
				})
		}

		const dataMeta = computed(() => {
			const localItemsCount = refBonusGroupListTable.value ? refBonusGroupListTable.value.localItems.length : 0
			return {
				from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
				to: perPage.value * (currentPage.value - 1) + localItemsCount,
				of: totalBonusGroup.value,
			}
		})

		const statusOptions = [
			{ label: 'Active', value: 1 },
			{ label: 'inActive', value: 2 },
		]

		const resolveStatus = status => {
			if (status == 1) return { label: 'Active', variant: 'primary' }
			if (status == 2) return { label: 'inActive', variant: 'danger' }
			return { label: 'Pending', variant: 'secondary' }
		}

		const resetFilter = () => {
			search.value.name = ''
			search.value.status = ''
			nameFilter.value = null
			statusFilter.value = null
		}

		const searchFilter = () => {
			nameFilter.value = search.value.name
			statusFilter.value = search.value.status
		}

		return {
			tableColumns,
			perPage,
			currentPage,
			totalBonusGroup,
			dataMeta,
			perPageOptions,
			sortBy,
			isSortDirDesc,
			refBonusGroupListTable,

			bonusGroupLists,
			refetchData,
			nameFilter,
			search,

			resetFilter,
			statusFilter,

			resolveStatus,
			statusOptions,

			formatDateTime,
			searchFilter,
		}
	},
}
</script>