<template>
	<div>
		<b-modal
			id="modal-add-bonus-group"
			cancel-variant="outline-secondary"
			ok-title="Add"
			cancel-title="Close"
			ok-variant="primary"
			centered
			title="New Bonus Group"
			@ok="confirmAddBonusGroup"
			@hidden="resetModal()"
		>
			<!-- form -->
			<validation-observer ref="addBonusGroup">
				<b-form
					method="POST"
					class="auth-reset-password-form"
					@submit.prevent="validationFormBonusGroup"
				>
					<!-- Field: Name -->
					<b-form-group
						label-for="name"
					>
						<label class="mb-1"><span class="text-danger">*</span> Name</label>
						<validation-provider
							#default="{ errors }"
							name="bonusGroupName"
							rules="required"
						>
							<b-form-input
								id="name"
								v-model="bonusGroupData.name"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

					<!-- <b-form-group
						label-for="content"
					>
						<label class="mb-1">{{ $t('Content') }}</label>
						<validation-provider
							#default="{ errors }"
							name="content"
                            rules="required"
						>
							<quill-editor
                                v-model="bonusGroupData.content"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                class="h-100"
                            />
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group
                        label-for="banner"
                    >
                        <label for=""><span class="text-danger">*</span> {{ $t('Banner') }} <span class="text-secondary">(Không > 10Mb)</span></label>
                        <validation-provider
                            #default="{ errors }"
                            name="banner"
                            rules="size:10240"
                        >
                            <b-form-file
                                v-model="bonusGroupData.banner"
                                input-id="banner"
                                accept="image/jpeg,image/png,image/gif,image/svg,image/jpg"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-button
                        v-show="bonusGroupData.banner"
                        variant="outline-danger"
                        class="btn-sm btn-icon"
                        @click="bonusGroupData.banner = null"
                    >
                        <feather-icon icon="XIcon"/>
                    </b-button> -->

				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import {
	BLink, BRow, BCol, BForm, BFormGroup, BFormFile, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea, BBadge,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
// eslint-disable-next-line
/* import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor' */

export default {
	components: {
		BLink, BRow, BCol, BForm, BFormGroup, BFormFile, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea, BBadge,
		BInputGroupAppend,
		ValidationProvider,
		ValidationObserver,
		vSelect,
		// quillEditor,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	data() {
		return {
			bonusGroupData: {
				name: '',
				// content: '',
				// banner: null,
			},
		}
	},
	methods: {
		validationFormBonusGroup() {
			this.$refs.addBonusGroup.validate().then(success => {
				if (success) {
					store.dispatch('bonus/addBonusGroup', this.bonusGroupData)
						.then(response => {
							if (response.status === 200) {
								if (response.data.code == '00') {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message || 'success',
											icon: 'CheckIcon',
											variant: 'success',
										},
									})
									this.$nextTick(() => {
										this.$bvModal.hide('modal-add-bonus-group')
										this.resetModal()
									})
									this.$emit('refetch-data')
								} else {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message,
											icon: 'AlertTriangleIcon',
											variant: 'danger',
										},
									})
								}
							}
						})
						.catch(error => {
							const { response } = error
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response,
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						})
				}

			})
		},
		resetModal() {
			this.bonusGroupData.name = ''
		},
		confirmAddBonusGroup(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.validationFormBonusGroup()
		}
	},
	setup() {
		// if (!store.hasModule('local-setting-role')) store.registerModule('local-setting-role', roleStoreModule)		
		return {
		}
	},
}
</script>
<style lang="scss">
// @import '@core/scss/vue/libs/quill.scss';
</style>