<template>
	<div>
		<b-modal
			id="modal-edit-bonus-group"
			cancel-variant="outline-secondary"
			ok-title="Ok"
			cancel-title="Close"
			ok-variant="primary"
			centered
			size="lg"
			title="Edit Bonus Group"
			@ok="confirmEditBonusGroup"
			@hidden="resetModal()"
		>
			<b-overlay
				:show="show"
				variant="transparent"
				no-wrap
			/>
			<!-- form -->
			<validation-observer ref="editBonusGroup">
				<b-form
					ref=""
					method="POST"
					class="auth-reset-password-form mt-2"
					@submit.prevent="validationFormBonusGroup"
				>
					<!-- Field: Name -->
					<b-form-group
						label-for="name"
					>
						<label class="mb-1"><span class="text-danger">*</span> Name</label>
						<validation-provider
							#default="{ errors }"
							name="limit"
							rules="required"
						>
							<b-form-input
								id="name"
								v-model="bonusGroupData.name"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								placeholder="Enter Name"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

					<!-- <b-form-group
						label-for="content"
					>
						<label class="mb-1">{{ $t('Content') }}</label>
						<validation-provider
							#default="{ errors }"
							name="content"
                            rules="required"
						>
							<quill-editor
                                v-model="bonusGroupData.content"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                class="h-100"
                            />
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

					<b-form-group
                        label-for="banner"
                    >
                        <label for=""><span class="text-danger">*</span> {{ $t('Banner') }} <span class="text-secondary">(Không > 10Mb)</span></label>
                        <validation-provider
                            #default="{ errors }"
                            name="banner"
                            rules="size:10240"
                        >
                            <b-form-file
                                v-model="bonusGroupData.banner"
                                input-id="banner"
                                accept="image/jpeg,image/png,image/gif,image/svg,image/jpg"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group v-show="bonusGroupData.showBanner">
                        <b-img
                            :src="bonusGroupData.showBanner"
                            fluid
                            style="width: 150px;"
                            class="mr-2 rounded"
                        />
                    </b-form-group>
                    <b-button
                        v-show="bonusGroupData.banner"
                        variant="outline-danger"
                        class="btn-sm btn-icon"
                        @click="bonusGroupData.banner = null"
                    >
                        <feather-icon icon="XIcon"/>
                    </b-button> -->

					<!-- Status -->
					<b-col
						cols="12"
					>
						<b-form-group
							label-for="status"
						>
							<label class="mb-1 d-block">Status</label>
							<b-form-radio
								v-model="bonusGroupData.status"
								name="status"
								value="1"
								inline
							>
								Active
							</b-form-radio>
							<b-form-radio
								v-model="bonusGroupData.status"
								name="status"
								value="2"
								inline
							>
								Inactive
							</b-form-radio>
						</b-form-group>
					</b-col>

				</b-form>
			</validation-observer>

		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import {
	BOverlay, BImg, BFormSpinbutton, BFormRadio, BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea, BBadge, BFormFile,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import baseUrl from '@/libs/base-url'
/* // eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor' */
// import paymentTypeStoreModule from './paymentTypeStoreModule'

export default {
	components: {
		BOverlay, BImg, BFormSpinbutton, BFormRadio, BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea, BBadge, BFormFile,
		BInputGroupAppend,
		ValidationProvider,
		ValidationObserver,
		vSelect,
		// quillEditor,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		bonusGroupDetail: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			bonusGroupData: {
				bonus_group_id: '',
				name: '',
				// content: '',
				// banner: '',
				// showBanner: null,
				status: 1,
			},
			show: false,
		}
	},
	watch: {
		async bonusGroupDetail(newVal) {
			if (newVal) {
				this.show = true
				await this.fillBonusGroupData(newVal)
				this.show = false
			}
		},
	},
	methods: {
		validationFormBonusGroup() {
			this.$refs.editBonusGroup.validate().then(success => {
				if (success) {
					store.dispatch('bonus/editBonusGroup', this.bonusGroupData)
						.then(response => {
							if (response.status === 200) {
								if (response.data.code === '00') {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message || 'success',
											icon: 'CheckIcon',
											variant: 'success',
										},
									})
									this.$nextTick(() => {
										setTimeout(() => {
											this.$bvModal.hide('modal-edit-bonus-group')
											this.resetModal()
										}, '500')
									})
									this.$emit('refetch-data')
								} else {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message,
											icon: 'AlertTriangleIcon',
											variant: 'danger',
										},
									})
								}
							}
						})
						.catch(error => {
							const { response } = error
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message,
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						})
				}
			})
		},
		async fillBonusGroupData(data) {
			this.bonusGroupData.bonus_group_id = data.id
			this.bonusGroupData.name = data.name
			/* this.bonusGroupData.content = data.content
			this.bonusGroupData.banner = ''
			this.bonusGroupData.showBanner = data.banner ? baseUrl + data.banner : null, */
			this.bonusGroupData.status = data.status
		},
		resetModal() {
			this.$emit('update:bonusGroupDetail', null)
			this.bonusGroupData.bonus_group_id = ''
			this.bonusGroupData.name = ''
			/* this.bonusGroupData.content = ''
			this.bonusGroupData.banner = '' */
			this.bonusGroupData.status = 1
		},
		confirmEditBonusGroup(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.validationFormBonusGroup()
		}
	},
	setup() {
		// if (!store.hasModule('payment-type')) store.registerModule('payment-type', paymentTypeStoreModule)
		return {
		}
	},
}
</script>
<style lang="scss">
// @import '@core/scss/vue/libs/quill.scss';
</style>